<template>
	<div>
		<b-modal
			id="modal-notification-detail"
			cancel-variant="outline-secondary"
			:cancel-title="$t('Close')"
			ok-variant="primary"
			centered
			:title="$t('Notification details')"
			size="lg"
			body-class="p-2"
			@hidden="resetModal()"
		>
			<b-table-simple
				responsive
				bordered
				table-class="table-narrow table-white-space table-highlight"
			>
				<b-tbody>
					<b-tr>
<!--						<b-td class="table-td-header">{{ $t('Name') }}</b-td>-->
<!--						<b-td>{{ notification.name }}</b-td>-->
<!--						<b-td class="table-td-header">{{ $t('Category') }}</b-td>-->
<!--						<b-td>{{ notification.category }}</b-td>-->
<!--					</b-tr>-->
<!--                    <b-tr>-->
<!--						<b-td class="table-td-header">{{ $t('Type') }}</b-td>-->
<!--						<b-td>{{ notification.type }}</b-td>-->
						<b-td class="table-td-header">{{ $t('Created by') }}</b-td>
						<b-td colspan="3">{{ notification.created_by }}</b-td>
					</b-tr>
                    <b-tr>
						<b-td class="table-td-header">{{ $t('Content') }}</b-td>
						<b-td colspan="3">
              <b-form-textarea
                  :value="notification.content"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  input-id="content"
                  rows="3"
                  max-rows="8"
                  no-auto-shrink
              />
            </b-td>
					</b-tr>
                    <b-tr>
						<b-td class="table-td-header">{{ $t('Created at') }}</b-td>
						<b-td>{{ notification.created_at ? formatDateTime(notification.created_at).replace('.000000Z', '') : '' }}</b-td>
						<b-td class="table-td-header">{{ $t('Updated at') }}</b-td>
						<b-td>{{ notification.updated_at ? formatDateTime(notification.updated_at).replace('.000000Z', '') : '' }}</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-modal>
	</div>
</template>

<script>
import {
	BModal, BTableSimple, BTr, BTh, BTd, BTbody, BTabs, BTab, BFormTextarea,
} from 'bootstrap-vue'
import { formatDateTime } from '@core/utils/filter'

export default {
	components: {
		BModal, BTableSimple, BTr, BTh, BTd, BTbody, BTabs, BTab, BFormTextarea,
	},
	props: {
		notificationData: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			transactionId: null,
			notification: {
                name: '',
                category: '',
                type: '',
                content: '',
                contentEn: '',
                created_at: '',
                updated_at: '',
                created_by: '',
			},
			show: false,
		}
	},
	watch: {
		notificationData: {
			deep: true,
			handler(newVal) {
				if (newVal) {
					this.notification = {
                        name: '',
                        category: '',
                        type: '',
                        content: newVal.content,
                        contentEn: '',
                        created_at: newVal.created_at,
                        updated_at: newVal.updated_at,
                        created_by: newVal.created_by, 
					}
				}
			}
		}
	},
	methods: {
		resetModal() {
			this.$emit('update:notificationData', null)
			this.notification = {}
		},
	},
	setup() {
		return {
			formatDateTime,
		}
	},
}
</script>
<style lang="scss" scoped>
table.table-narrow td {
	padding: 10px !important;
}
.dark-layout .table-highlight .table-td-header {
    background-color: #343d55 !important;
}
.table-highlight td.table-td-header {
    background-color: #f3f2f7;
}
</style>>